<template>
  <form
    class="form-horizontal"
    method="POST"
    :action="loginUrl"
  >
    <slot name="csrf" />

    <SslTextField
      v-model="data.Gebruikersnaam"
      name="Gebruikersnaam"
      label="Gebruikersnaam"
      placeholder="Gebruikersnaam"
      :validator="$v.data.Gebruikersnaam"
      :custom-error="loginError"
      required
      @input="() => $v.data.Gebruikersnaam.$touch()"
    />

    <SslPasswordField
      v-model="data.Wachtwoord"
      name="password"
      label="Wachtwoord"
      placeholder="Wachtwoord"
      :validator="$v.data.Wachtwoord"
      required
      @input="() => $v.data.Wachtwoord.$touch()"
    />

    <div class="field">
      <div class="control">
        <label
          for="remember-checkbox"
          class="checkbox"
        >
          <input
            id="remember-checkbox"
            type="checkbox"
            name="remember"
          />
          Ingelogd blijven
        </label>
      </div>
    </div>
    <div class="field">
      <p class="control">
        <button
          class="button is-success login-button"
          type="submit"
        >
          Login
        </button>
        <a
          class="button is-light"
          :href="gegevensVergetenUrl"
        >
          Inloggegevens vergeten?
        </a>
      </p>
    </div>
  </form>
</template>

<script>
  import {ref} from 'vue';
  import {required, maxLength} from 'vuelidate/lib/validators';

  import SslPasswordField from '../elements/SslPasswordField.vue';
  import SslTextField from '../elements/SslTextField.vue';

  export default {
    name: 'LoginForm',
    components: {
      SslPasswordField,
      SslTextField,
    },
    props: {
      loginUrl: {
        type: String,
        required: true,
      },
      gebruikersnaam: {
        type: String,
        default: '',
      },
      gegevensVergetenUrl: {
        type: String,
        required: true,
      },
      loginError: {
        type: String,
        default: '',
      },
    },
    setup(props) {
      // eslint-disable-next-line vue/no-setup-props-destructure
      const data = ref({
        Gebruikersnaam: props.gebruikersnaam,
        Wachtwoord: '',
      });

      return {
        data,
      };
    },
    validations: {
      data: {
        Gebruikersnaam: {
          required,
          maxLength: maxLength(100),
        },
        Wachtwoord: {
          required,
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .login-button {
    margin-right: 15px;
  }
</style>
