<template>
  <ValidatedFormField
    :validator="validator"
    :label="label"
  >
    <template #label>
      <slot name="label" />
    </template>
    <div class="block radiocontainer">
      <BRadio
        v-for="(title, key) in options"
        :key="key"
        :value="value"
        :native-value="key"
        :disabled="disabled"
        v-on="$listeners"
      >
        <span>{{ title }}</span>
      </BRadio>
    </div>
  </ValidatedFormField>
</template>

<script>
  import {BRadio} from 'buefy/src/components/radio';

  import ValidatedFormField from './ValidatedFormField.vue';

  export default {
    name: 'SslRadio',
    components: {
      BRadio,
      ValidatedFormField,
    },
    props: {
      options: {
        type: Object,
        required: true,
      },
      value: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      validator: {
        type: Object,
        default: undefined,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      updateInput(key) {
        this.$emit('input', key);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .radiocontainer {
    margin-left: -0.5em;

    .radio {
      margin-left: 0.5em;
    }
  }
</style>
