<template>
  <div class="loader-wrapper">
    <div
      v-if="loading"
      class="is-loading"
      :style="loadingStyles"
    >
      <slot name="loadingDescription">
        <p>Gegevens worden geladen...</p>
      </slot>
      <LoadingComponent />
    </div>
    <template v-else-if="hasError">
      <slot name="errorDescription">
        Onbekende fout opgetreden bij ophalen gegevens.
      </slot>
    </template>
    <template v-else>
      <slot />
    </template>
  </div>
</template>

<script setup lang="ts">
  import {computed} from 'vue';

  import LoadingComponent from '@/js/vue/components/LoadingComponent.ts.vue';

  interface Props {
    loading: boolean;
    hasError?: boolean;
    placeholderHeight?: string;
    placeholderWidth?: string;
  }

  const props = withDefaults(defineProps<Props>(), {
    hasError: false,
    placeholderHeight: '100px',
    placeholderWidth: '50%',
  });

  const loadingStyles = computed(() => ({
    height: props.placeholderHeight,
    width: props.placeholderWidth,
  }));
</script>

<style lang="scss" scoped>
  .is-loading {
    opacity: 0.75;
  }
</style>
