<template>
  <BField
    :label="label"
    :class="customClass"
    :type="type"
    :message="errorMessage"
    v-bind="$attrs"
  >
    <template #label>
      <slot name="label" />
    </template>
    <slot />
  </BField>
</template>

<script>
  import {BField} from 'buefy/src/components/field';
  import {singleErrorExtractorMixin} from 'vuelidate-error-extractor';

  export default {
    name: 'ValidatedFormField',
    components: {
      BField,
    },
    extends: singleErrorExtractorMixin,
    props: {
      customErrorMessage: {
        type: String,
        default: '',
      },
    },
    computed: {
      hasAnyErrors() {
        return this.hasErrors || this.hasCustomError;
      },
      hasCustomError() {
        return this.customErrorMessage !== '';
      },
      errorMessage() {
        return this.hasCustomError ? this.customErrorMessage : this.firstErrorMessage;
      },
      type() {
        return this.hasAnyErrors ? 'is-danger' : this.isValid ? 'is-success' : null;
      },
      customClass() {
        return {
          'has-text-danger': this.hasAnyErrors,
          'has-text-success': this.isValid,
        };
      },
    },
  };
</script>
