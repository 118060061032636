<template>
  <div>
    <h3
      v-if="!noTitle"
      class="is-size-3"
    >
      Contactgegevens
    </h3>
    <template v-if="!readOnly">
      <!--POSTCODE-->
      <SslTextField
        v-model="data.Postcode"
        label="Postcode*"
        placeholder="Postcode"
        autocomplete="postal-code"
        :validator="$v.data.Postcode"
        @input="() => { $v.data.Postcode.$touch(); adresVeranderd(); }"
      />

      <!--HUISNUMMER-->
      <SslTextField
        v-model="data.Huisnummer"
        label="Huisnummer*"
        placeholder="Huisnummer"
        :validator="$v.data.Huisnummer"
        @input="() => { $v.data.Huisnummer.$touch(); adresVeranderd(); }"
      />

      <!--STRAAT-->
      <SslTextField
        v-model="data.Straat"
        label="Straat*"
        placeholder="Straat"
        :validator="$v.data.Straat"
        @input="() => $v.data.Straat.$touch()"
      />

      <!--PLAATSNAAM-->
      <SslTextField
        v-model="data.Plaats"
        label="Woonplaats*"
        placeholder="Woonplaats"
        :validator="$v.data.Plaats"
        @input="() => $v.data.Plaats.$touch()"
      />

      <!--MOBIEL NUMMER-->
      <SslTextField
        v-model="data.telefoonnummers.mobiel.Telefoonnummer"
        type="tel"
        autocomplete="tel"
        label="Mobiel nummer leerling*"
        placeholder="06-12345678"
        :validator="$v.data.telefoonnummers.$each.mobiel.Telefoonnummer"
        @blur="() => $v.data.telefoonnummers.$each.mobiel.Telefoonnummer.$touch()"
      />

      <!--TELEFOON THUIS-->
      <SslTextField
        v-model="data.telefoonnummers.thuis.Telefoonnummer"
        type="tel"
        label="Telefoonnummer thuis"
        placeholder="071-1234567"
        :validator="$v.data.telefoonnummers.$each.thuis.Telefoonnummer"
        @blur="() => $v.data.telefoonnummers.$each.thuis.Telefoonnummer.$touch()"
      />

      <!--TELEFOONNUMMER OUDER-->
      <SslTextField
        v-model="data.telefoonnummers.ouder.Telefoonnummer"
        type="tel"
        label="Mobiel nummer ouder (voor noodgevallen)*"
        placeholder="06-12345678"
        :validator="$v.data.telefoonnummers.$each.ouder.Telefoonnummer"
        @blur="() => $v.data.telefoonnummers.$each.ouder.Telefoonnummer.$touch()"
      >
        <template #label>
          Mobiel nummer ouder (voor noodgevallen)*
          <SslInfoPopover title="Mobiel nummer">
            <p>
              Dit nummer is belangrijk zodat we in noodgevallen met
              een van je ouders/verzorgers contact op kunnen nemen.
            </p>
          </SslInfoPopover>
        </template>
      </SslTextField>
      <SslRadio
        v-model="data.telefoonnummers.ouder.Type"
        label=""
        :options="{Vader: 'vader', Moeder: 'moeder', Overig: 'anders, namelijk...'}"
        :validator="$v.data.telefoonnummers.$each.ouder.Type"
        @input="() => $v.data.telefoonnummers.$each.ouder.Type.$touch()"
      />
      <SslTextField
        v-if="data.telefoonnummers.ouder.Type === 'Overig'"
        v-model="data.telefoonnummers.ouder.Opmerkingen"
        label=""
        :validator="$v.data.telefoonnummers.$each.ouder.Opmerkingen"
        @input="() => $v.data.telefoonnummers.$each.ouder.Opmerkingen.$touch()"
      />

      <!--EMAILADRES 1-->
      <SslTextField
        v-model.trim="data.Email1"
        label="Eerste e-mailadres*"
        placeholder="Email 1"
        type="email"
        autocomplete="email"
        :validator="$v.data.Email1"
        @blur="updateEmail1"
      />

      <!--EMAILADRES 2-->
      <SslTextField
        v-model.trim="data.Email2"
        label="Tweede e-mailadres*"
        placeholder="Email 2"
        type="email"
        :validator="$v.data.Email2"
        @blur="updateEmail2"
      >
        <template #label>
          Tweede e-mailadres*
          <SslInfoPopover title="Tweede e-mailadres">
            <p>
              Alle communicatie verloopt via email.
              Als je zelf geen tweede mailadres hebt, geef dan bijvoorbeeld het e-mailadres van een van je ouders op.
            </p>
          </SslInfoPopover>
        </template>
      </SslTextField>
    </template>
    <template v-else>
      <table class="table">
        <tr>
          <th>Adres</th>
          <td>{{ data.Straat }} {{ data.Huisnummer }}<br />{{ data.Postcode }}<br />{{ data.Plaats }}</td>
        </tr>
        <tr>
          <th>Mobiel leerling</th>
          <td>{{ data.telefoonnummers.mobiel.Telefoonnummer }}</td>
        </tr>
        <tr v-if="heeftHuistelefoon()">
          <th>Telefoon (thuis)</th>
          <td>{{ data.telefoonnummers.thuis.Telefoonnummer }}</td>
        </tr>
        <tr>
          <th>Mobiel nummer (nood)</th>
          <td>{{ data.telefoonnummers.ouder.Telefoonnummer }} ({{ data.telefoonnummers.ouder.Type }})</td>
        </tr>
        <tr>
          <th>E-mail 1</th>
          <td>{{ data.Email1 }}</td>
        </tr>
        <tr>
          <th>E-mail 2</th>
          <td>{{ data.Email2 }}</td>
        </tr>
      </table>
    </template>
  </div>
</template>

<script>
  import {ref} from 'vue';
  import {email, maxLength, minLength, required, requiredIf} from 'vuelidate/lib/validators';

  import zoekAdres from '../../util/zoekAdres';

  import SslInfoPopover from '../elements/SslInfoPopover.ts.vue';
  import SslRadio from '../elements/SslRadio.vue';
  import SslTextField from '../elements/SslTextField.vue';

  import {alphaNumTrimmed} from '../validation/index';

  import usePostRequest from '@/js/vue/util/postRequest';

  const uniekTelefoonnummer = function(value) {
    return Object.values(this.data.telefoonnummers)
      .filter((nummer) => (nummer.Telefoonnummer === value) && (value !== null))
      .length <= 1;
  };

  const uniqueUser = function() {
    return this.unique;
  };

  export default {
    name: 'PartialFormContact',
    components: {
      SslInfoPopover,
      SslRadio,
      SslTextField,
    },
    props: {
      data: {
        type: Object,
        required: true,
      },
      persoonlijk: {
        type: Object,
        default: null,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      noTitle: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      const unique = ref(true);

      const postEndpoint = '/api/registratie/validate_unique';
      const {
        isSaving,
        postRequest,
        showPostRequestErrorDialogs,
      } = usePostRequest(postEndpoint, (data) => unique.value = data.unique);
      showPostRequestErrorDialogs.value = false;

      return {
        unique,
        isSaving,
        postRequest,
      };
    },
    watch: {
      'data.telefoonnummers': {
        handler() {
          this.data.telefoonnummers_gewijzigd = true;
        },
        deep: true, // watch for nested changes
      },
    },
    methods: {
      heeftHuistelefoon() {
        const nummer = this.data.telefoonnummers.thuis.Telefoonnummer;
        return (nummer !== undefined) && (nummer !== '');
      },
      updateEmail1() {
        this.$v.data.Email1.$touch();
        this.validateUniqueUser();
      },
      updateEmail2() {
        this.$v.data.Email2.$touch();
        this.validateUniqueUser();
      },
      adresVeranderd() {
        if (this.data.Huisnummer && this.data.Postcode) {
          zoekAdres(this.data.Postcode, this.data.Huisnummer)
            .then((adres) => {
              if (adres.Straat) {
                this.data.Straat = adres.Straat;
                this.$v.data.Straat.$touch();
              }
              if (adres.Plaats) {
                this.data.Plaats = adres.Plaats;
                this.$v.data.Plaats.$touch();
              }
            });
        }
      },
      validateUniqueUser() {
        this.unique = true; // in case of doubt: return true
        if (this.data.Email1 && this.data.Email2 && this.persoonlijk) {
          const postData = {
            persoonlijk: this.persoonlijk,
            contact: {
              Email1: this.data.Email1,
              Email2: this.data.Email2,
            },
          };
          this.postRequest(postData);
        }
      },
    },
    validations: {
      data: {
        uniqueUser: uniqueUser,
        Straat: {
          required,
          maxLength: maxLength(100),
        },
        Huisnummer: {
          required, alphaNumTrimmed,
          maxLength: maxLength(10),
        },
        Postcode: {
          required, alphaNumTrimmed,
          maxLength: maxLength(10),
        },
        Plaats: {
          required,
          maxLength: maxLength(100),
        },
        telefoonnummers: {
          $each: {
            Telefoonnummer: {
              required: requiredIf((nummer) => (!nummer.optioneel)),
              minLength: minLength(10),
              maxLength: maxLength(14),
              uniekTelefoonnummer,
            },
            Type: {
              required,
            },
            Opmerkingen: {
              required: requiredIf((nummer) => (nummer.Type === 'Overig')),
            },
          },
        },
        Email1: {
          required, email,
          maxLength: maxLength(100),
        },
        Email2: {
          required, email,
          andersDanEmail1(email) {
            return email.toLowerCase() !== this.data.Email1.toLowerCase();
          },
          maxLength: maxLength(100),
        },
      },
    },
  };
</script>
