// Add a click event on all "navbar-burger" elements
document.querySelectorAll<HTMLElement>('.navbar-burger').forEach((el) => {
  el.addEventListener('click', () => {
    // Get the target from the "data-target" attribute
    const targetId = el.dataset.target;
    const targetElement = targetId ? document.getElementById(targetId) : undefined;

    // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
    el.classList.toggle('is-active');
    targetElement?.classList.toggle('is-active');
  });
});
