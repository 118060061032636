const dagOptions = {
  weekday: 'short',
  month: 'long',
  day: 'numeric',
};

const timeOptions = {
  hour: 'numeric',
  minute: 'numeric',
};

export default {
  methods: {
    formatDagString(dag) {
      const date = new Date(dag);
      return date.toLocaleDateString('nl-NL', dagOptions);
    },
    formatTimeString(time) {
      const date = new Date(time);
      return date.toLocaleTimeString('nl-NL', timeOptions);
    },
    dagNaarDagString(value, format = 'dd D MMM YYYY') {
      if (typeof value === 'string') {
        return this.$dayjs(value, 'YYYY-MM-DD').format(format);
      } else if (value instanceof Date) {
        return this.$dayjs(value).format(format);
      }

      return 'onbekende datum';
    },
  },
};
