<template>
  <ValidatedFormField
    :label="fieldLabel"
    :validator="validator"
    validation-message="Geef een cijfer op tussen de 1 en de 10"
    class="cijfers"
    :horizontal="!vertical"
  >
    <BInput
      :value="value"
      :disabled="disabled"
      placeholder="Cijfer"
      type="number"
      min="1"
      max="10"
      step="0.1"
      lang="nl"
      v-on="$listeners"
    />
  </ValidatedFormField>
</template>

<script>
  import {BInput} from 'buefy/src/components/input';

  import ValidatedFormField from './ValidatedFormField.vue';

  export default {
    name: 'SslCijferField',
    components: {
      BInput,
      ValidatedFormField,
    },
    props: {
      label: {
        type: String,
        default: '',
      },
      vak: {
        type: Object,
        default: function() {
          return {};
        },
      },
      value: {
        type: [String, Number],
        required: true,
      },
      validator: {
        type: Object,
        default: function() {
          return {};
        },
      },
      vertical: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      fieldLabel() {
        return this.label ? this.label : (this.vak ? this.vak.naam : '');
      },
    },
    methods: {
      updateInput(value) {
        this.$emit('input', value);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .cijfers {
    :deep(.field-label) {
      flex-basis: 4rem; // Zorg dat uitlijning ook bij 'aardrijkskunde' gelijk is
    }

    :deep(.field-body) {
      align-self: center; // Zorg dat verticale uitlijning matcht bij lange vakken
    }
  }
</style>
