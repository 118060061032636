import {type BDialogConfig} from 'buefy/types/components';
import {
  reactive,
  ref,
  unref,
  toRefs,
  getCurrentInstance,
  type Ref,
} from 'vue';

type MaybeRef<T> = T | Ref<T>;

const emptyCallback = () => {/* empty */};

export default function usePostRequest<TBody, TData>(
  url: MaybeRef<string>,
  handlePostResponse: MaybeRef<(response: TData) => void> = emptyCallback,
  afterPostRequest: MaybeRef<() => void> = emptyCallback,
  handleInputInvalid: MaybeRef<(data?: unknown) => void> = emptyCallback,
) {
  const app = getCurrentInstance();
  const $buefy = app?.proxy?.$root?.$buefy;

  const state = reactive({
    isSaving: false,
    error: null,
  });

  const showPostRequestErrorDialogs = ref(true);
  const postRequestErrorTitle = ref('Fout opgetreden');

  const showAlertDialog = (
    message: string,
    config: Omit<BDialogConfig, 'message'> = {},
  ) => showPostRequestErrorDialogs.value
    ? $buefy?.dialog.alert({
      message,
      title: postRequestErrorTitle.value,
      type: 'is-danger',
      hasIcon: true,
      ...config,
    })
    : undefined;

  const inputInvalid = (data: unknown) => showAlertDialog('Ongeldige invoer', {
    onConfirm: () => unref(handleInputInvalid)(data),
  });

  const postRequest = async(data: TBody) => {
    state.isSaving = true;
    $http.post<TData>(unref(url), data)
      .then((response) => {
        const handler = unref(handlePostResponse);
        handler(response.data);
      })
      .catch((error) => {
        state.error = error;
        let title = postRequestErrorTitle.value;
        let message = 'Onbekende fout';
        if (error.response) {
          if (error.response.status === 419 || error.response.status === 401) {
            // invalid CSRF token or authentication error
            title = 'Sessie verlopen';
            message = 'Je sessie is verlopen, herlaad deze pagina of log opnieuw in';
          } else if (error.response.status === 422) {
            inputInvalid(error.response.data);
            return;
          } else if ((typeof error.response.data === 'object') && ('errors' in error.response.data)) {
            if (Array.isArray(error.response.data.errors)) {
              message = error.response.data.errors[0];
            } else if (typeof error.response.data.errors === 'string') {
              message = error.response.data.errors;
            }
          } else if (typeof error.response.data === 'string') {
            message = error.response.data;
          }
        } else if (error.request) {
          title = 'Onbekende fout';
          message = 'Je verzoek kon helaas niet worden verwerkt';
        } else {
          title = 'Interne fout';
          message = 'Je verzoek kon helaas niet worden verstuurd';
        }

        showAlertDialog(message, {title});
      })
      .finally(() => {
        state.isSaving = false;
        const handler = unref(afterPostRequest);
        handler();
      });
  };

  return {
    ...toRefs(state),
    showPostRequestErrorDialogs,
    postRequestErrorTitle,
    postRequest,
  };
}
