<template>
  <div>
    <h3 class="is-size-3">
      Persoonlijk
    </h3>

    <template v-if="!readOnly">
      <!--VOORNAAM-->
      <SslTextField
        v-model="data.Voornaam"
        label="Voornaam*"
        placeholder="Voornaam"
        autocomplete="given-name"
        :validator="$v.data.Voornaam"
        @input="() => $v.data.Voornaam.$touch()"
      />

      <!--TUSSENVOEGSEL-->
      <SslTextField
        v-model="data.Tussenvoegsel"
        label="Tussenvoegsel"
        placeholder="Tussenvoegsel"
        autocomplete="additional-name"
        :validator="$v.data.Tussenvoegsel"
        @input="() => $v.data.Tussenvoegsel.$touch()"
      />

      <!--ACHTERNAAM-->
      <SslTextField
        v-model="data.Achternaam"
        label="Achternaam*"
        placeholder="Achternaam"
        autocomplete="family-name"
        :validator="$v.data.Achternaam"
        @input="() => $v.data.Achternaam.$touch()"
      />

      <!--GESLACHT-->
      <SslRadio
        v-model="data.Geslacht"
        label="Geslacht*"
        :options="{M: 'M', V: 'V'}"
        :validator="$v.data.Geslacht"
        @input="() => $v.data.Geslacht.$touch()"
      />

      <!--GEBOORTEDATUM-->
      <SslDatepicker
        v-model="data.Geboortedatum"
        label="Geboortedatum*"
        placeholder="dd-mm-jjjj"
        autocomplete="bday"
        :validator="$v.data.Geboortedatum"
        @input="() => $v.data.Geboortedatum.$touch()"
      />
    </template>
    <template v-else>
      <table class="table">
        <tr><th>Naam</th><td>{{ data.Voornaam }} {{ data.Tussenvoegsel }} {{ data.Achternaam }}</td></tr>
        <tr><th>Geslacht</th><td>{{ data.Geslacht }}</td></tr>
        <tr><th>Geboortedatum</th><td>{{ displayDatum }}</td></tr>
      </table>
    </template>
  </div>
</template>

<script>
  import {between, maxLength, required} from 'vuelidate/lib/validators';

  import SslDatepicker from '../elements/SslDatepicker.vue';
  import SslRadio from '../elements/SslRadio.vue';
  import SslTextField from '../elements/SslTextField.vue';

  export default {
    name: 'PartialFormPersoonlijk',
    components: {
      SslDatepicker,
      SslRadio,
      SslTextField,
    },
    props: {
      data: {
        type: Object,
        required: true,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        dateFormatLanguage: 'nl-NL',
        dateFormatOptions: {
          format: 'YYYY-MM-DD',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        },
      };
    },
    computed: {
      displayDatum: function() {
        if (this.data.Geboortedatum instanceof Date) {
          return this.data.Geboortedatum.toLocaleDateString(this.dateFormatLanguage, this.dateFormatOptions);
        }
        return '';
      },
    },
    watch: {
      'data.Geboortedatum': {
        immediate: true,
        handler(val) {
          if (typeof this.data.Geboortedatum === 'string') {
            this.data.Geboortedatum = new Date(val);
          }
        },
      },
    },
    validations: {
      data: {
        Voornaam: {
          required,
          maxLength: maxLength(100),
        },
        Tussenvoegsel: {
          maxLength: maxLength(20),
        },
        Achternaam: {
          required,
          maxLength: maxLength(100),
        },
        Geslacht: {
          required,
        },
        Geboortedatum: {
          required,
          geboortedatumBetween: between(
            new Date(Date.now()).setFullYear(new Date(Date.now()).getFullYear() - 100),
            new Date(Date.now()).setFullYear(new Date(Date.now()).getFullYear() - 10),
          ),
        },
      },
    },
  };
</script>
