<template>
  <div>
    <h3 class="is-size-3">
      Introductie
    </h3>
    <div class="content">
      <p>
        Welkom bij het aanmeldingsformulier voor de {{ cursusreeks.Naam }}.
        Zorg ervoor dat je, voordat je je aanmeldt, eerst de <a
          :href="inleidingUrl"
          target="_blank"
          title="Inleiding"
        >inleiding</a> hebt gelezen.
        Daar vind je alle informatie over de cursus, zoals de inhoud van de cursus, de kosten en de cursusdata.
      </p>
      <p>
        De aanmeldingsprocedure duurt vijf à tien minuten en vindt plaats in drie stappen.
        Zorg ervoor dat je de volgende zaken bij de hand hebt:
      </p>

      <ul>
        <li>
          Het gemiddelde cijfer van je schoolexamens (SE-cijfers) tot nu toe
          voor de vakken waarvoor je je wilt inschrijven.
        </li>
        <li>Je agenda, zodat je kunt aangeven in welke periodes je beschikbaar bent.</li>
        <li>Twee verschillende e-mailadressen: je eigen e-mailadres en bijvoorbeeld een e-mailadres van een ouder.</li>
      </ul>

      <BMessage
        v-if="newUser"
        type="is-warning"
        has-icon
      >
        Gebruik dit formulier niet als je je al hebt aangemeld voor deze of een andere cursus.
        Wanneer je je wilt inschrijven voor een van onze andere cursussen of je vakken wilt aanpassen,
        log dan in via <a
          :href="wijzigenUrl"
          title="Gegevens wijzigen"
        >deze site</a>.
      </BMessage>
    </div>
  </div>
</template>

<script>
  import {BMessage} from 'buefy/src/components/message';

  export default {
    name: 'PartialFormIntroductie',
    components: {
      BMessage,
    },
    props: {
      cursusreeks: {
        type: Object,
        required: true,
      },
      wijzigenUrl: {
        type: String,
        required: true,
      },
      newUser: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      inleidingUrl: function() {
        return 'https://www.sslleiden.nl/' + this.cursusreeks.Trainingstype + '/inleiding';
      },
    },
  };
</script>
