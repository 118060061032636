<template>
  <div class="nieuws">
    <h2 class="is-size-2">
      Nieuws
    </h2>
    <LazyLoadingWrapper
      :loading="isLoading"
      :has-error="hasError"
    >
      <p v-if="nieuwsberichten.length === 0">
        Er zijn op dit moment geen nieuwsberichten.
      </p>
      <SslNieuwsbericht
        v-for="(bericht, key) in nieuwsberichten"
        :key="key"
        :nieuwsbericht="bericht"
      />
    </LazyLoadingWrapper>
  </div>
</template>

<script setup lang="ts">
  import {computed} from 'vue';

  import SslNieuwsbericht from '../elements/SslNieuwsbericht.ts.vue';
  import LazyLoadingWrapper from '../wrappers/LazyLoadingWrapper.ts.vue';

  import {type NieuwsBericht} from '@/js/types/models/algemeen';

  import useGetRequest from '@/js/vue/util/getRequest';

  interface Props {
    nieuwsEndpoint: string;
  }
  type NieuwsEndpointResponse = Array<NieuwsBericht>;

  const props = defineProps<Props>();

  const {
    result: nieuwsberichten,
    isLoading,
    hasError,
  } = useGetRequest<NieuwsEndpointResponse>(computed(() => props.nieuwsEndpoint), []);
</script>
