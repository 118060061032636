<template>
  <div>
    <h2 class="is-size-3">
      Bijzonderheden
    </h2>
    <template v-if="!readOnly">
      <div class="content has-text-grey-dark is-marginless is-content-dense">
        <p>Zijn er nog zaken waarvan je denkt dat het goed is als wij ervan op de hoogte zijn? Je kunt denken aan:</p>
        <ol class="is-dense">
          <li>Zaken die relevant kunnen zijn voor onze docenten, zoals dyslexie, faalangst of adhd;</li>
          <li>
            Zaken die relevant kunnen zijn voor de organisatie,
            bijvoorbeeld dat je school (een deel van) het cursusbedrag vergoedt.
          </li>
        </ol>
        <p>
          Let op: heb je vragen of opmerkingen waar je een reactie op wilt hebben,
          stuur dan een <a href="mailto:info@sslleiden.nl">mailtje</a>.
          Er wordt namelijk niet gereageerd op wat je hieronder invult.
        </p>
      </div>
      <div v-if="data.opmerkingen">
        <label class="label">Al eerder doorgegeven opmerkingen</label>
        <textarea
          v-model="data.opmerkingen"
          class="textarea"
          readonly
        />
      </div>
      <SslTextField
        v-model.trim="data.opmerkingenNieuw"
        label="Extra informatie en/of opmerkingen"
        type="textarea"
        placeholder="Ruimte voor informatie en/of opmerkingen"
        :validator="$v.data.opmerkingen"
        @input="updateInput"
      />
    </template>
    <template v-else>
      <label class="label">
        Opmerkingen
        <SslInfoPopover title="Opmerkingen">
          <p>
            Het is niet mogelijk om je opmerkingen aan te passen.<br />
            Mocht je dit wel willen, of zijn er andere zaken waarvan wij op de hoogte moeten zijn,
            stuur ons dan gerust een <a href="mailto:info@sslleiden.nl">mailtje</a>.
          </p>
        </SslInfoPopover>
      </label>
      <textarea
        v-model="data.opmerkingen"
        class="textarea nonedit"
        readonly
      />
    </template>
  </div>
</template>

<script>
  import {maxLength} from 'vuelidate/lib/validators';

  import SslInfoPopover from '../elements/SslInfoPopover.ts.vue';
  import SslTextField from '../elements/SslTextField.vue';

  export default {
    name: 'PartialFormBijzonderheden',
    components: {
      SslInfoPopover,
      SslTextField,
    },
    props: {
      data: {
        type: Object,
        required: true,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
    },
    validations: {
      data: {
        opmerkingen: {
          maxLength: maxLength(65535),
        },
      },
    },
    methods: {
      updateInput() {
        this.$emit('input', this.data);
      },
    },
  };
</script>

<style lang="scss" scoped>
  textarea.nonedit {
    min-width: 50%;
    max-width: 600px;
    outline: none;
    resize: none;
    padding: 3px 8px;
  }
</style>
