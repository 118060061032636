<template>
  <ValidatedFormField :label="label">
    <BRadioButton
      v-for="(title, key) in options"
      :key="key"
      :type="types[key]"
      :disabled="disabled"
      :value="value"
      :native-value="key"
      v-on="$listeners"
    >
      <BIcon
        v-if="icons && icons[key]"
        :icon="icons[key]"
      />
      <span>{{ title }}</span>
    </BRadioButton>
  </ValidatedFormField>
</template>

<script>
  import {BIcon} from 'buefy/src/components/icon';
  import {BRadioButton} from 'buefy/src/components/radio';

  import ValidatedFormField from './ValidatedFormField.vue';

  export default {
    name: 'SslRadioButton',
    components: {
      BIcon,
      BRadioButton,
      ValidatedFormField,
    },
    props: {
      options: {
        type: Object,
        required: true,
      },
      icons: {
        type: Object,
        default: function() {
          return {};
        },
      },
      types: {
        type: Object,
        default: function() {
          return {};
        },
      },
      value: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
    },
    methods: {
      updateInput(key) {
        this.$emit('input', key);
      },
    },
  };
</script>
