<template>
  <div>
    <FormWizard
      ref="formWizard"
      :title="title"
      :start-index="startIndex"
      subtitle=""
      color="#00897e"
      error-color="hsl(348, 100%, 61%)"
      next-button-text="Volgende"
      back-button-text="Vorige"
      finish-button-text="Opslaan"
      @on-complete="finish"
      @on-change="onChangeStep"
    >
      <h1
        slot="title"
        class="title is-size-1"
      >
        {{ title }}
      </h1>

      <TabContent
        :before-change="validateTab2"
        title="Beschikbaarheid"
        class="column is-half-desktop is-offset-one-quarter-desktop"
      >
        <PartialFormVakken
          ref="partialFormVakken"
          :data="formData.vakken"
          :vakken-overzicht="vakkenOverzicht"
          :niveau="niveau"
        />
        <PartialFormBeschikbaarheid
          ref="partialFormBeschikbaarheid"
          :data="formData.beschikbaarheid"
          :vakken-overzicht="vakkenOverzicht"
          :niveau="niveau"
          :vakken="formData.vakken.vakken"
          :cursusreeks="cursusreeks"
          :beschikbaarheid-min-aantal="instellingen.BeschikbaarheidMinAantal"
          :vergrendelingen="vergrendelingen"
        />
      </TabContent>

      <TabContent
        :before-change="validateTab3"
        title="Voorkeur"
      >
        <PartialFormVoorkeur
          ref="partialFormVoorkeur"
          :data="formData.voorkeur"
          :vakken-overzicht="vakkenOverzicht"
          :niveau="niveau"
          :vakken="formData.vakken.vakken"
          :indelingen="formData.vakken.indelingen"
          :beschikbaarheid="formData.beschikbaarheid"
          :vraag-om-voorkeurslengte="vraagOmVoorkeurslengte"
          :wijzigingen-overnemen="wijzigingenOvernemen"
        />
        &nbsp;<br />

        <div class="warnings-and-errors">
          <BMessage
            v-if="geenIndeling.length > 0"
            type="is-warning"
            has-icon
            title="Waarschuwing"
          >
            We hebben je voor een aantal vakken (nog) niet kunnen indelen.
            Geef voor de volgende vakken meer beschikbaarheid of voorkeuren op om zeker te zijn van een plaats:
            <ul>
              <li
                v-for="vak in geenIndeling"
                :key="vak.Vak_ID"
              >
                {{ vak.naam }}
              </li>
            </ul>
          </BMessage>
        </div>
      </TabContent>
      <template
        slot="prev"
      >
        <a class="button is-success mobile-is-very-large">
          <span class="icon mdi mdi-arrow-left" />
          <span class="is-hidden-mobile">Vorige</span>
        </a>
      </template>
      <template
        slot="next"
      >
        <a class="button is-success mobile-is-very-large">
          <span class="is-hidden-mobile">Volgende</span>
          <span class="icon mdi mdi-arrow-right" />
        </a>
      </template>
      <template
        slot="finish"
      >
        <a
          class="button is-success"
          :class="[{ 'is-loading': isSaving }, 'button', 'is-success']"
          :disabled="!formValid"
        >
          <span>Opslaan</span>
          <span class="icon mdi mdi-send" />
        </a>
      </template>
    </FormWizard>
  </div>
</template>

<script>
  import {BMessage} from 'buefy/src/components/message';
  import {computed, ref} from 'vue';
  import {FormWizard, TabContent} from 'vue-form-wizard';
  import 'vue-form-wizard/dist/vue-form-wizard.min.css';

  import PartialFormBeschikbaarheid from '../partials/PartialFormBeschikbaarheid.vue';
  import PartialFormVakken from '../partials/PartialFormVakken.vue';
  import PartialFormVoorkeur from '../partials/PartialFormVoorkeur.vue';
  import useBeforeUnload from '../util/beforeUnload';
  import useCursusViaUrl from '../util/cursusViaUrl';

  import usePostRequest from '@/js/vue/util/postRequest';

  export default {
    name: 'VakkenForm',
    components: {
      BMessage,
      FormWizard,
      TabContent,
      PartialFormVakken,
      PartialFormBeschikbaarheid,
      PartialFormVoorkeur,
    },
    props: {
      cursusreeks: {
        type: Object,
        required: true,
      },
      redirectUrl: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const isDirty = ref(true);
      useBeforeUnload(isDirty);

      const vakkenOverzicht = ref({});
      const {periodeVakFromParams} = useCursusViaUrl(vakkenOverzicht);

      const postEndpoint = computed(() => `/api/wijzigen/update_voorkeuren/${props.cursusreeks.slug}`);
      const handlePostResponse = ref();
      const {
        isSaving,
        postRequest,
        postRequestErrorTitle,
      } = usePostRequest(postEndpoint, handlePostResponse);
      postRequestErrorTitle.value = 'Fout opgetreden bij opslaan vakken';

      return {
        isDirty,
        vakkenOverzicht,
        periodeVakFromParams,
        isSaving,
        handlePostResponse,
        postRequest,
      };
    },
    data() {
      return {
        formData: {
          vakken: {
            vakken: [],
            cijfers: {},
            indelingen: {},
          },
          beschikbaarheid: {},
          voorkeur: {
            voorkeursperiode: {},
            voorkeurslengte: {},
            opmerkingen: {},
          },
        },
        niveau: '',
        instellingen: {},
        geenIndeling: [],
        vraagOmVoorkeurslengte: false,
        vergrendelingen: [],
        wijzigingenOvernemen: true,
        activeStep: 0,
        indelingAlertGiven: false,
        validatieElementen: [], // De array met alle te valideren elementen,
        formValid: true,
      };
    },
    computed: {
      title() {
        return this.cursusreeks.Naam;
      },
      startIndex() {
        const params = new URLSearchParams(window.location.search);
        const res = params.get('tab') ? Number(params.get('tab')) : 0;
        return (res < 2 && res > -1) ? res : 0;
      },
    },
    watch: {
      'formData.vakken.indelingen': function() {
        this.indelingAlertGiven = false;
      },
      'formData.beschikbaarheid': function() {
        this.indelingAlertGiven = false;
      },
    },
    mounted() {
      this.handlePostResponse = this.onPostRequestSucces; // deduplicate this on full setup migration
      if (this.startIndex === 1) {
        this.$refs.partialFormVoorkeur.voorkeurOpen = true;
      }
      this.validatieElementen = [
        {
          ref: this.$refs.partialFormVakken,
          tab: 1,
          verplicht: true,
        },
        {
          ref: this.$refs.partialFormBeschikbaarheid,
          tab: 1,
          verplicht: true,
        },
      ];
      $http.get('/api/overzicht/' + this.cursusreeks.slug + '/vakken')
        .then((response) => {
          this.niveau = response.data.niveau;
          this.vakkenOverzicht = response.data.overzicht;
          this.instellingen = response.data.instellingen;
          this.vraagOmVoorkeurslengte = this.instellingen.VraagOmVoorkeurslengte;
          this.formData.beschikbaarheid = response.data.beschikbaarheden;
          const huidigeVakken = response.data.vakken.vakken;
          this.formData.vakken.vakken = this.vakkenOverzicht.cursussen[this.niveau]
            .filter((vak) =>
              // Neem alleen de vakken waarvoor de leerling al is ingeschreven
              (String(vak.Vak_ID) in huidigeVakken))
            .map((vak) => {
              const res = vak;
              res.ingeschreven = true;
              res.aanpasbaar = huidigeVakken[vak.Vak_ID].aanpasbaar;
              res.wachtlijst = huidigeVakken[vak.Vak_ID].wachtlijst;
              res.indeling = huidigeVakken[vak.Vak_ID].indeling;
              return res;
            });
          //sanity check: heeft de leerling al vakken?
          if (this.formData.vakken.vakken.length > 0) {
            this.formData.voorkeur = response.data.voorkeur;
            this.formData.vakken.cijfers = response.data.vakken.cijfers;
            this.formData.vakken.indelingen = response.data.vakken.indelingen;
          }
          this.vergrendelingen = response.data.vergrendelingen;
          this.wijzigingenOvernemen = response.data.overnemen;

          const [gevondenPeriode, gevondenVak] = this.periodeVakFromParams();
          if (gevondenVak && gevondenPeriode) {
            const huidigeVakkenIds = this.formData.vakken.vakken.map((vak) => vak.Vak_ID);
            if (!huidigeVakkenIds.includes(gevondenVak.Vak_ID)) {
              // Als het vak nog NIET bij de geselecteerde vakken staat
              gevondenVak.aanpasbaar = true;
              gevondenVak.wachtlijst = false;
              gevondenVak.indeling = null;
              this.formData.vakken.vakken.push(gevondenVak);
            }
            // Als er een vak + periode gevonden is
            this.formData.beschikbaarheid[gevondenPeriode.Periode_ID] = '1';
            this.formData.voorkeur.voorkeursperiode[gevondenVak.Vak_ID] = gevondenPeriode.Periode_ID;
            this.cursusGeselecteerdViaUrl = true;
          }

          this.checkIfFormValid(); //valideer zodat je direct kunt opslaan
        });
    },
    methods: {
      finish() {
        if (this.checkIfFormValid() && !this.isSaving) {
          this.postRequest(this.formData);
        } else if (!this.formValid) {
          // Als de validatie is gefaald, gaan we naar het juiste tabblad
          if (this.$refs.partialFormVakken.$v.$invalid) {
            this.$buefy.dialog.alert('Er is iets mis met je vakken of cijfers');
            this.$refs.formWizard.changeTab(1, 0); //hardcoded! moet nog beter!
            return;
          }
          if (this.$refs.partialFormBeschikbaarheid.$v.$invalid) {
            this.$buefy.dialog.alert('Er is iets mis met je beschikbaarheid');
            this.$refs.formWizard.changeTab(1, 0, false); //hardcoded! moet nog beter!
            setTimeout(() => { // omdat changeTab anders nog niet goed verwerkt is
              const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
              const partial = document.getElementById('beschikbaarheidWarnings');
              if (supportsNativeSmoothScroll) {
                partial.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
              } else {
                partial.scrollIntoView({block: 'end', inline: 'nearest'});
              }
              return;
            }, 500);
          }
        }
      },
      onPostRequestSucces() {
        this.isDirty = false;
        history.pushState(null, 'bijwerken geslaagd', this.redirectUrl);
        window.history.go();
      },
      onChangeStep() {
        this.checkIfFormValid();
        // Scroll naar boven: smooth als ondersteund, niet-smooth in andere gevallen.
        const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
        if (supportsNativeSmoothScroll) {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        } else {
          window.scrollTo(0, 0); // Scroll naar boven
        }
      },
      validateTab2() {
        if (import.meta.env.VITE_FORM_ALLOW_INVALID === 'true') {
          return true;
        }

        this.$refs.partialFormVakken.$v.data.$touch();
        if (!this.$refs.partialFormVakken.$v.data.vakken.minimumOne) {
          this.$buefy.dialog.alert('Kies minstens 1 vak waarvoor je een cursus wilt volgen');
          return false;
        }
        if (!this.$refs.partialFormVakken.$v.data.vakken.elkVakCijfer) {
          this.$buefy.dialog.alert('Geef voor elk vak je cijfer op');
          return false;
        }
        if (this.$refs.partialFormVakken.$v.data.cijfers.$invalid) {
          this.$buefy.dialog.alert('Er is iets mis met je cijfers');
          return false;
        }

        this.$refs.partialFormBeschikbaarheid.$v.data.$touch();
        if (!this.$refs.partialFormBeschikbaarheid.$v.data.elkVakMinstens) {
          this.$buefy.dialog.alert('Vul voor elk vak minstens 1 periode Ja in');
          return false;
        } else if (!this.$refs.partialFormBeschikbaarheid.$v.data.beschikbaarheidGroterDanVakken) {
          this.$buefy.dialog.alert('Vul voor voldoende periodes Ja in');
          return false;
        } else if (!this.$refs.partialFormBeschikbaarheid.$v.data.elkVakMinstensAangeraden) {
          /*
           *   this.$buefy.dialog.alert(`Het wordt aangeraden om voor alle vakken minstens ${this.instellingen.BeschikbaarheidMinAantal} periodes beschikbaar te zijn`);
           *   return false;
           */
          gtag('event', 'elkVakMinstensAangeraden', {
            'event_category': 'validatie',
            'event_label': this.title,
          });
        }
        return true;
      },
      validateTab3() {
        this.geenIndeling = this.genereerIndeling();
        if (import.meta.env.VITE_FORM_ALLOW_INVALID === 'true') {
          return true;
        }
        if (this.geenIndeling.length > 0 && !this.indelingAlertGiven) {
          this.$buefy.dialog.alert('Het is ons niet gelukt om je voor al je vakken in te delen');
          this.indelingAlertGiven = true;
          return false;
        }
        return true;
      },
      beschikbarePeriodesBijVak(vakID, indelingen) {
        return Object.keys(this.vakkenOverzicht.cursussen[this.niveau]
          .find((vak) => vak.Vak_ID === vakID).periodes)
          .filter((id) => (this.formData.beschikbaarheid[id] === '1' && !(id in indelingen))) // Alleen beschikbare periodes tonen
          .map(Number);
      },
      genereerIndeling() {
        const res = [];
        const vakkenIngedeeld = Object.values(this.formData.vakken.indelingen);
        const nieuweIndelingen = [];
        const itdv = this.formData.vakken.vakken
          .filter((vak) => !vakkenIngedeeld.includes(vak.Vak_ID))
          .map((vak) => [vak, this.beschikbarePeriodesBijVak(vak.Vak_ID, this.formData.vakken.indelingen)])
          .sort((a, b) => (a[1].length > b[1].length) ? 1 : -1);
        itdv.forEach((vak) => {
          vak[1] = vak[1].filter((periode) => !nieuweIndelingen.includes(periode));
          const per = this.deelVakIn(vak);
          if (per !== 0) {
            this.formData.vakken.indelingen[per] = vak[0].Vak_ID;
            nieuweIndelingen.push(per);
          } else {
            res.push(vak[0]);
          }
        });
        return res;
      },
      deelVakIn(vakPeriodes) {
        const vak = vakPeriodes[0];
        const periodes = vakPeriodes[1];
        let wachtlijstOptie = true;
        const voorkeursLengte = this.formData.voorkeur.voorkeurslengte[vak.Vak_ID];
        const lengte = (voorkeursLengte === 'drieDaags') ? 3 : (voorkeursLengte === 'tweeDaags') ? 2 : 0;
        let res = 0;
        for (const periode of periodes) {
          const vakperiode = vak.periodes[periode];
          if (vakperiode.status === 'Open') {
            if ((lengte === 0 || lengte === (vakperiode.dagen.length - vakperiode.nietopdagen.length))) {
              // eerste open periode met juiste lengte = bingo!
              return vakperiode.Periode_ID;
            } else if (res === 0 || wachtlijstOptie) {
              // er is tot nu toe geen of alleen een wachtlijstoptie gevonden: sla deze periode op als beter alternatief
              wachtlijstOptie = false;
              res = vakperiode.Periode_ID;
            }
          } else if (vakperiode.status === 'Wachtlijst' && (res === 0)) {
            // er is tot nu toe geen optie gevonden: sla deze periode op als beter alternatief
            res = vakperiode.Periode_ID;
          }
        }
        return res;
      },
      checkIfFormValid() {
        if (import.meta.env.VITE_FORM_ALLOW_INVALID === 'true') {
          this.formValid = true;
          return true;
        }
        this.formValid = !this.validatieElementen
          .filter((entry) => entry.verplicht)
          .reduce((result, entry) => result || entry.ref.$v.$invalid, false);
        return this.formValid;
      },
    },
    validations: {},
  };
</script>

<!-- eslint-disable-next-line vue/enforce-style-attribute -- Remove this non-scoped block; see #298 -->
<style lang="scss" src="@/sass/vueFormWizard.scss" />
