<template>
  <ValidatedFormField
    :validator="validator"
    :label="label"
  >
    <!-- De mobile-native=false is een fix voor bug met iOS Safari waar b-datepicker geen inputEvent geeft. -->
    <BDatepicker
      ref="datepicker"
      :value="value"
      :placeholder="placeholder"
      icon="calendar-today"
      :date-formatter="dateFormatter"
      :date-parser="dateParser"
      :month-names="monthNames"
      :day-names="dayNames"
      :min-date="minDate"
      :max-date="maxDate"
      :focused-date="focusedDate"
      :years-range="[-10,5]"
      :mobile-native="false"
      editable
      v-on="$listeners"
    />
  </ValidatedFormField>
</template>

<script>
  import {BDatepicker} from 'buefy/src/components/datepicker';
  import IMask from 'imask';

  import ValidatedFormField from './ValidatedFormField.vue';

  const dateTemplate = 'DD-MM-YYYY';

  export default {
    name: 'SslDatepicker',
    components: {
      BDatepicker,
      ValidatedFormField,
    },
    props: {
      label: {
        type: String,
        required: true,
      },
      value: {
        type: Date,
        default: function() {
          return new Date(new Date(new Date(Date.now()).getFullYear() - 18), 0, 1);
        },
      },
      placeholder: {
        type: String,
        default: '',
      },
      validator: {
        type: Object,
        default: function() {
          return {};
        },
      },
    },
    data() {
      return {
        monthNames: [
          'januari',
          'februari',
          'maart',
          'april',
          'mei',
          'juni',
          'juli',
          'augustus',
          'september',
          'oktober',
          'november',
          'december',
        ],
        dayNames: ['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo'],
        minDate: new Date(1900, 0, 1),
        maxDate: new Date(new Date(Date.now()).setFullYear(new Date(Date.now()).getFullYear() - 10)),
        focusedDate: new Date(new Date(new Date(Date.now()).getFullYear() - 18), 0, 1),
        maskRef: null, // Bewaar een verwijzing naar het input mask
        mask: {
          mask: Date,
          pattern: dateTemplate,
          lazy: false,
          format: this.dateFormatter,
          parse: this.dateParser,
          blocks: {
            YYYY: {
              mask: IMask.MaskedRange,
              from: 1900,
              to: 9999,
            },
            MM: {
              mask: IMask.MaskedRange,
              from: 1,
              to: 12,
            },
            DD: {
              mask: IMask.MaskedRange,
              from: 1,
              to: 31,
            },
          },
        },
      };
    },
    mounted() {
      // Initialiseer het input mask op het daadwerkelijke input-element.
      const inputEl = this.$refs.datepicker.$el.querySelector('input');
      this.maskRef = IMask(inputEl, this.mask);
    },
    methods: {
      updateInput(value) {
        this.$emit('input', value);
      },
      dateFormatter(date) {
        return this.$dayjs(date).format(dateTemplate);
      },
      dateParser(dateString) {
        return dateString ? this.$dayjs(dateString, dateTemplate).toDate() : null;
      },
    },
  };
</script>
