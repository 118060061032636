<template>
  <div
    v-show="notificatiesZichtbaar"
    class="notifications"
  >
    <div
      v-if="getoondeNotificaties.betalingsWaarschuwing"
      class="notification is-warning"
    >
      <p>
        Aangezien de cursus op zeer korte termijn van start gaat,
        willen we je vriendelijk vragen om het cursusbedrag direct (maar uiterlijk binnen één uur) te voldoen
        met behulp van de knop in het onderstaande <a href="#betalingen">betalingsoverzicht</a>.
        Wanneer het cursusbedrag niet tijdig is bijgeschreven op onze rekening,
        zijn we genoodzaakt je aanmelding te annuleren.
      </p>
      <p class="subtext">
        Vergoedt je school de cursus voor je? Dan zullen wij de betaling voor je verwerken.
        Ben je bij de inschrijving vergeten te noteren dat je school de cursus vergoedt?
        Stuur dan een e-mail naar <a href="mailto:info@sslleiden.nl">info@sslleiden.nl</a>
        met daarin je naam en de afspraak met je school.
      </p>
    </div>
    <div
      v-if="getoondeNotificaties.resultatenOnvolledig"
      class="notification is-warning"
    >
      Je hebt nog niet je (volledige) examenresultaten ingevuld.
      Zou je de uitslag op <a :href="resultatenFormEndpoint">dit formulier</a> kunnen invullen?
      Alvast bedankt!
    </div>
    <div
      v-if="getoondeNotificaties.resultatenVolledig"
      class="notification is-success"
    >
      Bedankt voor het invullen van je examenresultaten!
      Je kunt de ingevulde gegevens <a :href="resultatenFormEndpoint">hier</a> terugvinden.
    </div>
  </div>
</template>

<script setup lang="ts">
  import {computed} from 'vue';

  import useGetRequest from '../util/getRequest';

  type Notificaties = 'betalingsWaarschuwing' | 'resultatenVolledig' | 'resultatenOnvolledig';

  interface BetalingsStatusResponse {
    toonWaarschuwing: boolean;
    nogTeBetalen: number;
  }

  type ResultatenStatus = 'geen' | 'volledig' | 'onvolledig' | null;
  interface ResultatenStatusResponse {
    status: ResultatenStatus;
  }

  interface Props {
    betalingsStatusEndpoint: string;
    resultatenStatusEndpoint: string;
    resultatenFormEndpoint: string;
  }
  const props = defineProps<Props>();

  const {
    result: resultatenStatusResponse,
  } = useGetRequest<ResultatenStatusResponse>(
    computed(() => props.resultatenStatusEndpoint),
    {status: null},
  );
  const resultatenStatus = computed(() => resultatenStatusResponse.value.status);

  const {
    result: betalingsStatus,
  } = useGetRequest<BetalingsStatusResponse>(
    computed(() => props.betalingsStatusEndpoint),
    {toonWaarschuwing: false, nogTeBetalen: 0},
  );

  const getoondeNotificaties = computed<Record<Notificaties, boolean>>(() => ({
    betalingsWaarschuwing: betalingsStatus.value.toonWaarschuwing && betalingsStatus.value.nogTeBetalen > 0,
    resultatenVolledig: resultatenStatus.value === 'volledig',
    resultatenOnvolledig: resultatenStatus.value === 'onvolledig' || resultatenStatus.value === 'geen',
  }));
  const notificatiesZichtbaar = computed(() => Object.values(getoondeNotificaties.value).some((value) => value));
</script>

<style lang="scss" scoped>
  .subtext {
    font-size: 0.9rem;
    font-style: italic;
  }
</style>
