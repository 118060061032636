/* eslint-disable import/order */

import Vue from 'vue';

/**
 * ---------------------
 * Vue components
 * ---------------------
 */

// Self-made components

import PartialFormContact from './partials/PartialFormContact.vue';
import PartialFormPersoonlijk from './partials/PartialFormPersoonlijk.vue';
import PartialFormSchool from './partials/PartialFormSchool.vue';
import PartialFormBeschikbaarheid from './partials/PartialFormBeschikbaarheid.vue';
import PartialFormVoorkeur from './partials/PartialFormVoorkeur.vue';
import PartialFormBijzonderheden from './partials/PartialFormBijzonderheden.vue';
import RegisterForm from './apps/RegisterForm.vue';
import VakkenForm from './apps/VakkenForm.vue';
import LoginForm from './apps/LoginForm.vue';
import WachtwoordResetForm from './apps/WachtwoordResetForm.ts.vue';
import ResultatenForm from './apps/ResultatenForm.vue';
import CursusNotificaties from './apps/CursusNotificaties.ts.vue';
import ActueelOverzicht from './apps/ActueelOverzicht.ts.vue';
import NieuwsOverzicht from './apps/NieuwsOverzicht.ts.vue';
import BeschikbaarheidOverzicht from './apps/BeschikbaarheidOverzicht.ts.vue';
import InschrijvingenOverzicht from './apps/InschrijvingenOverzicht.vue';
import BetalingenOverzicht from './apps/BetalingenOverzicht.ts.vue';
import VriendenOverzicht from './apps/VriendenOverzicht.ts.vue';
import ViewAndEditWrapper from './wrappers/ViewAndEditWrapper.vue';

Vue.component('PartialFormContact', PartialFormContact);
Vue.component('PartialFormPersoonlijk', PartialFormPersoonlijk);
Vue.component('PartialFormSchool', PartialFormSchool);
Vue.component('PartialFormBeschikbaarheid', PartialFormBeschikbaarheid);
Vue.component('PartialFormVoorkeur', PartialFormVoorkeur);
Vue.component('PartialFormBijzonderheden', PartialFormBijzonderheden);
Vue.component('ViewAndEditWrapper', ViewAndEditWrapper);
Vue.component('RegisterForm', RegisterForm);
Vue.component('VakkenForm', VakkenForm);
Vue.component('ResultatenForm', ResultatenForm);
Vue.component('CursusNotificaties', CursusNotificaties);
Vue.component('ActueelOverzicht', ActueelOverzicht);
Vue.component('NieuwsOverzicht', NieuwsOverzicht);
Vue.component('BeschikbaarheidOverzicht', BeschikbaarheidOverzicht);
Vue.component('InschrijvingenOverzicht', InschrijvingenOverzicht);
Vue.component('LoginForm', LoginForm);
Vue.component('WachtwoordResetForm', WachtwoordResetForm);
Vue.component('BetalingenOverzicht', BetalingenOverzicht);
Vue.component('VriendenOverzicht', VriendenOverzicht);

/**
 * ---------------------
 * Vuelidate
 * ---------------------
 */

// @ts-expect-error -- Package has no types
import Vuelidate from 'vuelidate';
// @ts-expect-error -- Package has no types
import VuelidateErrorExtractor, {templates} from 'vuelidate-error-extractor';

const validationMessages = {
  required: 'Dit veld is verplicht',
  minLength: 'Gebruik minimaal {min} karakters',
  maxLength: 'Gebruik maximaal {max} karakters',
  alphaNum: 'Dit veld mag geen bijzondere tekens bevatten',
  alphaNumTrimmed: 'Dit veld mag geen bijzondere tekens bevatten',
  alpha: 'Dit veld mag geen cijfers of bijzondere tekens bevatten',
  andersDanEmail1: 'De twee e-mailadressen moeten verschillend zijn',
  email: 'Vul een geldig e-mailadres in',
  minimumOne: 'Selecteer minstens 1 vak',
  between: 'Waarde moet tussen {min} en {max} liggen',
  geboortedatumBetween: 'Geboortedatum moet minstens 10 en maximaal 100 jaar geleden zijn',
  elkePeriode: 'Geef voor elke periode je beschikbaarheid op',
  eenDecimaal: 'Geef je cijfer op in 1 decimaal nauwkeurig',
  elkVakCijfer: 'Geef voor elk vak je cijfer op',
  uniekTelefoonnummer: 'Geef verschillende telefoonnummers op',
};

Vue.use(Vuelidate);
Vue.use(VuelidateErrorExtractor, {
  template: templates.singleErrorExtractor.foundation6,
  messages: validationMessages,
});

/**
 * ---------------------
 * dayjs
 * ---------------------
 */

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/nl';

dayjs.extend(customParseFormat);
dayjs.locale('nl');

Vue.prototype.$dayjs = dayjs;

/**
 * ---------------------
 * Setup Vue globals
 * ---------------------
 */

import Buefy from 'buefy';

Vue.use(Buefy);

/**
 * ---------------------
 * Make createVue method with default settings
 * ---------------------
 */

const createVueApp = (el = '#app', options = {}) => new Vue({
  el: el,
  name: 'VueApp',
  ...options,
});

export default createVueApp;
