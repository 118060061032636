// Based on https://logaretm.com/blog/my-favorite-5-vuejs-composables/#useeventlistener

import {
  watch,
  unref,
  isRef,
  onBeforeUnmount,
  onMounted,
  type Ref,
} from 'vue';

// This can be extended further if needed, but seems to cover most use cases
interface EventMap extends HTMLElementEventMap, DocumentEventMap, WindowEventMap {}
type EventName = keyof EventMap;

/*
 * Override EventTarget addEventListener with generic that can detect event type from passed name;
 * based on the generic implementations in lib.dom.ts for e.g. Window and Document
 */
interface EventTarget {
  addEventListener<K extends keyof EventMap>(
    type: K,
    listener: (e: EventMap[K]) => void,
    options?: boolean | AddEventListenerOptions): void;
  removeEventListener<K extends keyof EventMap>(
    type: K,
    listener: (e: EventMap[K]) => void,
    options?: boolean | EventListenerOptions): void;
}

type NullableEventTarget = EventTarget | null;
export type EventListenerTarget = Ref<NullableEventTarget> | NullableEventTarget;

export default function useEventListener<TEventName extends EventName>(
  event: TEventName,
  handler: (e: EventMap[TEventName]) => void,
  target: EventListenerTarget = window,
) {
  const addEventListener = (eventTarget: EventListenerTarget = target) => unref(eventTarget)
    ?.addEventListener(event, handler);
  const removeEventListener = (eventTarget: EventListenerTarget = target) => unref(eventTarget)
    ?.removeEventListener(event, handler);

  onMounted(addEventListener);
  onBeforeUnmount(removeEventListener);

  if (isRef(target)) {
    watch(target, (newValue, oldValue) => {
      removeEventListener(oldValue);
      addEventListener(newValue);
    });
  }

  return {
    addEventListener,
    removeEventListener,
  };
}
