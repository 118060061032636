<template>
  <SslTextField
    ref="textField"
    :type="inputType"
    :icon-right="passwordIcon"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script setup>
  import {computed, ref, onMounted} from 'vue';

  import SslTextField from './SslTextField.vue';

  const textField = ref(null);
  const showPassword = ref(false);

  const passwordIcon = computed(() => showPassword.value ? 'eye-off' : 'eye');
  const inputType = computed(() => showPassword.value ? 'text' : 'password');

  onMounted(() => {
    // We dynamically obtain the Buefy-rendered icon using its class
    const passwordIconElement = textField.value.$el.querySelector('.icon.is-right');

    passwordIconElement.addEventListener('pointerover', () => {
      showPassword.value = true;
    });
    passwordIconElement.addEventListener('pointerout', () => {
      showPassword.value = false;
    });
  });
</script>
