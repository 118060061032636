import {type Ref} from 'vue';

import {
  type Periode,
  type Vak,
  type VakkenOverzicht,
} from '@/js/types/models/vakkenoverzicht';

interface CursusParams {
  periode: string | null;
  vak: string | null;
}
interface ValidatedCursusParams {
  periode: number;
  vak: number;
}

export default function useCursusViaUrl(
  vakkenOverzicht: Ref<VakkenOverzicht>,
  url: string = window.location.href,
) {
  const searchParams = new URL(url).searchParams;
  const cursusParams: CursusParams = {
    periode: searchParams.get('Periode_ID'),
    vak: searchParams.get('Vak_ID'),
  };

  const validatedCursusParams = (): ValidatedCursusParams | false => {
    // First ensure both parameters are present
    if (cursusParams.vak === null || cursusParams.periode === null) {
      return false;
    }

    // Then validate they are valid positive integers
    const [periodeId, vakId] = [parseInt(cursusParams.periode), parseInt(cursusParams.vak)];
    if (isNaN(vakId) || vakId <= 0 || isNaN(periodeId) || periodeId <= 0) {
      return false;
    }

    return {
      periode: periodeId,
      vak: vakId,
    };
  };

  const getPeriode = (periodeId: Periode['Periode_ID']) =>
    vakkenOverzicht.value.periodes.find((periode) => periode.Periode_ID === periodeId);
  const getVak = (vakId: Vak['Vak_ID']) => {
    const vakkenPerNiveau = Object.values(vakkenOverzicht.value.cursussen); // Nested array of cursussen per niveau
    const vakkenLijst = vakkenPerNiveau.flat(1); // Flatten to obtain all cursussen in a single array

    return vakkenLijst.find((vak) => vak.Vak_ID === vakId);
  };

  const periodeVakFromParams = () => {
    const params = validatedCursusParams();

    return params ? [getPeriode(params.periode), getVak(params.vak)] as const : [undefined, undefined] as const;
  };

  return {
    periodeVakFromParams,
  };
}
