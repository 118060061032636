<template>
  <ValidatedFormField
    :validator="validator"
    :label="label"
    :custom-error-message="customErrorMessage"
  >
    <template #label>
      <slot name="label" />
    </template>
    <BInput
      :type="type"
      :value="value"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled"
      :required="required"
      :icon-right="iconRight"
      icon-right-clickable
      v-bind="$attrs"
      v-on="$listeners"
      @input="() => inputTriggered = true"
    />
  </ValidatedFormField>
</template>

<script>
  import {BInput} from 'buefy/src/components/input';

  import ValidatedFormField from './ValidatedFormField.vue';

  export default {
    name: 'SslTextField',
    components: {
      BInput,
      ValidatedFormField,
    },
    props: {
      label: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: 'text',
      },
      name: {
        type: String,
        default: '',
      },
      customError: {
        type: String,
        default: '',
      },
      value: {
        type: String,
        default: '',
      },
      iconRight: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      validator: {
        type: Object,
        default: undefined,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        inputTriggered: false, // wordt 'true' wanneer er een input event is geweest
      };
    },
    computed: {
      customErrorMessage() {
        return this.inputTriggered ? '' : this.customError;
      },
    },
    methods: {
      updateInput(value) {
        this.$emit('input', value);
      },
    },
  };
</script>
