<template>
  <div class="beschikbaarheid">
    <h2 class="is-size-2">
      Beschikbaarheid
    </h2>
    <LazyLoadingWrapper
      :loading="isLoading"
      :has-error="hasError"
    >
      <p>Je bent beschikbaar in de volgende periode(s):</p>
      <div class="content">
        <ul>
          <li
            v-for="(beschikbaarheid, key) in beschikbaarheden"
            :key="key"
            v-text="beschikbaarheid.periode.omschrijving"
          />
        </ul>
      </div>
      <BButton
        type="is-success"
        icon-left="calendar-clock"
        tag="a"
        :href="beschikbaarheidWijzigenEndpoint"
      >
        Beschikbaarheid aanpassen
      </BButton>
    </LazyLoadingWrapper>
  </div>
</template>

<script setup lang="ts">
  import {BButton} from 'buefy/src/components/button';
  import {computed} from 'vue';

  import LazyLoadingWrapper from '../wrappers/LazyLoadingWrapper.ts.vue';

  import {type Beschikbaarheid} from '@/js/types/models/cursusreeks';

  import useGetRequest from '@/js/vue/util/getRequest';

  interface Props {
    beschikbaarheidEndpoint: string;
    beschikbaarheidWijzigenEndpoint: string;
  }
  type BeschikbaarheidEndpointResponse = Array<Beschikbaarheid>;

  const props = defineProps<Props>();

  const {
    result: beschikbaarheden,
    isLoading,
    hasError,
  } = useGetRequest<BeschikbaarheidEndpointResponse>(computed(() => props.beschikbaarheidEndpoint), []);
</script>
