<template>
  <div
    v-show="actueelZichtbaar"
    class="actueel"
  >
    <h2 class="is-size-2">
      Actueel
    </h2>
    <div
      v-for="(klas, key) in actueleKlassen"
      :key="key"
      class="klas notification is-success"
    >
      <h3 class="title is-5">
        {{ klas.cursus.periode.omschrijving }}
      </h3>
      <h4 class="subtitle is-6">
        {{ klas.cursus.vak.volledigeNaam }}
      </h4>
      <template v-if="locatieBekend(klas)">
        <template v-if="heeftMeerdereLokalen(klas)">
          <h6 class="card-title">
            Aanvangslocatie
          </h6>
          <p>
            <span v-html="klas.aanvangsLocatie.Link" /> <!-- eslint-disable-line vue/no-v-html -->
            (lokaal {{ klas.aanvangs_lokaal.Naam }})
          </p>
          <h6 class="card-title">
            Lokalen
            <SslInfoPopover
              title="Lokalen"
              icon-color="white"
            >
              Zie het kopje 'Locaties' hieronder voor een toelichting op de gebruikte afkortingen.
            </SslInfoPopover>
          </h6>
          <p>
            <span v-html="klas.htmlLokaalOmschrijving" /> <!-- eslint-disable-line vue/no-v-html -->
          </p>
          <h6 class="card-title">
            Locaties
          </h6>
          <ul class="locaties">
            <li
              v-for="(gebouw, gKey) in klas.gebouwen"
              :key="gKey"
            >
              {{ gebouw.Code }}: <span v-html="gebouw.Link" /> <!-- eslint-disable-line vue/no-v-html -->
            </li>
          </ul>
        </template>
        <template v-else>
          <h6 class="card-title">
            Lokaal
          </h6>
          {{ klas.aanvangs_lokaal.Naam }} -
          <span v-html="klas.aanvangsLocatie.Link" /> <!-- eslint-disable-line vue/no-v-html -->
        </template>
        <template v-if="lokalenMetRoutebeschrijving(klas).length > 0">
          <h6 class="card-title">
            Hoe vind ik mijn lokaal?
          </h6>
          <p>
            Onze docenten staan bij de ingang voor je klaar om je het lokaal te wijzen.
            Dit eerste stappenplannetje 'Hoe vind ik mijn lokaal?' is dus puur voor de zekerheid!
          </p>
          <template v-if="klas.lokalen.length > 1">
            <BTabs
              type="is-toggle"
              class="routebeschrijvingen"
            >
              <BTabItem
                v-for="(lokaal, lKey) in lokalenMetRoutebeschrijving(klas)"
                :key="lKey"
                class="beschrijving"
                :label="lokaal.Afkorting"
              >
                {{ lokaal.Routebeschrijving }}
              </BTabItem>
            </BTabs>
          </template>
          <div
            v-else
            class="beschrijving enkel"
            v-text="klas.lokalen[0].Routebeschrijving"
          />
        </template>
      </template>
      <template v-else>
        <h6 class="card-title">
          Lokaal
        </h6>
        <p>
          Nog niet bekend
          <SslInfoPopover
            title="Aanvangslocatie"
            icon-color="white"
          >
            Bekijk onze
            <a
              :href="aanvangsLocatieUrl"
              target="_blank"
            >site</a> voor een overzicht van alle aanvangslocaties.
          </SslInfoPopover>
        </p>
      </template>
    </div>
    <SslMaaltijdbon
      v-if="vandaagCursus"
      :maaltijdbon-endpoint="maaltijdbonEndpoint"
      :maaltijdbon-kopen-endpoint="maaltijdbonKopenEndpoint"
    />
  </div>
</template>

<script setup lang="ts">
  import {BTabItem, BTabs} from 'buefy/src/components/tabs';
  import {computed} from 'vue';

  import SslInfoPopover from '../elements/SslInfoPopover.ts.vue';
  import SslMaaltijdbon from '../elements/SslMaaltijdbon.ts.vue';

  import {type Klas} from '@/js/types/models/cursusreeks';
  import useGetRequest from '@/js/vue/util/getRequest';

  // See IndelingController@actueel
  interface ActueelEndpointResponse {
    actueleKlassen: Array<Klas>;
    vandaagCursus: boolean;
  }

  const aanvangsLocatieUrl = 'https://www.sslleiden.nl/aanvangslocatie';

  interface Props {
    actueelEndpoint: string;
    maaltijdbonEndpoint: string;
    maaltijdbonKopenEndpoint: string;
  }
  const props = defineProps<Props>();

  const {result} = useGetRequest<ActueelEndpointResponse>(computed(() => props.actueelEndpoint), {
    actueleKlassen: [],
    vandaagCursus: false,
  });
  const actueleKlassen = computed(() => result.value.actueleKlassen);
  const vandaagCursus = computed(() => result.value.vandaagCursus);

  const actueelZichtbaar = computed(() => actueleKlassen.value.length > 0);

  const locatieBekend = (klas: Klas) => klas.aanvangs_lokaal !== null;
  const heeftMeerdereLokalen = (klas: Klas) => klas.lokalen.length > 1;
  const lokalenMetRoutebeschrijving = (klas: Klas) => klas.lokalen
    .filter((lokaal) => lokaal.Routebeschrijving !== null);
</script>

<style lang="scss" scoped>
  @use "@/sass/variables";

  .card-title {
    font-weight: 600;
    margin-top: 1.4rem;
    margin-bottom: 0;
  }

  .klas {
    margin-bottom: 0;
  }

  :deep(.routebeschrijvingen) {
    padding: 0.2rem 0;

    .tab-content {
      padding: 0 1rem;
    }

    a {
      background-color: rgba(variables.$primary, 0.65);
      border: none !important;
      font-weight: 500;
      text-decoration: none !important;
    }
  }

  .beschrijving {
    white-space: pre-line;

    &.enkel {
      margin-top: 0.6rem;
    }
  }
</style>
