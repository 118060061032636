<template>
  <div class="wrapper">
    <div
      v-if="maaltijdbon"
      class="maaltijdbon"
      :class="maaltijdbonColor"
    >
      <div class="maaltijdbon-title">
        <h3 class="title is-5">
          Maaltijdbon {{ formatDateString(maaltijdbon.Dag) }}
          <SslInfoPopover
            title="Maaltijdbon"
            icon-color="white"
          >
            <template v-if="opties.aanpasbaarTot">
              Je kunt tot uiterlijk {{ formatTimeString(opties.aanpasbaarTot) }} je dieetvoorkeur aanpassen.
            </template>
            Op de pagina <a
              href="https://www.sslleiden.nl/wat-eten-we"
              target="_blank"
            >Wat eten we?</a> kun je zien wat er op het menu staat.
            Zie ook de <a
              href="https://www.sslleiden.nl/FAQ#locatieavondeten"
              target="_blank"
            >vragen over mee eten op de cursus</a>.
          </SslInfoPopover>
        </h3>
        <h4 class="subtitle is-6">
          {{ leerling.volledigeNaam }}
        </h4>
      </div>
      <SslDropdown
        v-if="aanpasbaar"
        v-model="maaltijdbon.Dieetwens"
        :options="dieetOpties"
        size="is-small"
      />
      <div v-else>
        Dieetwens: {{ maaltijdbon.Dieetwens }}
      </div>
      <em>Laat deze bon zien bij aanvang van het cursusbuffet</em>
    </div>
    <template v-else-if="koopbaar">
      <BButton
        type="is-primary"
        icon-left="plus-circle"
        tag="a"
        :href="maaltijdbonKopenEndpoint"
      >
        Maaltijdbon kopen {{ formatDate(new Date(Date.now())) }}
      </BButton>
      <SslInfoPopover
        title="Maaltijdbon"
        class="maaltijdbon-info"
      >
        <template v-if="opties.teKoopTot">
          Je kunt vandaag tot {{ formatTimeString(opties.teKoopTot) }} een maaltijdbon kopen.
        </template>
        Op de pagina <a
          href="https://www.sslleiden.nl/wat-eten-we"
          target="_blank"
        >Wat eten we?</a> kun je zien wat er op het menu staat.
        Zie ook de <a
          href="https://www.sslleiden.nl/FAQ#locatieavondeten"
          target="_blank"
        >vragen over mee eten op de cursus</a>.
      </SslInfoPopover>
    </template>
  </div>
</template>

<script setup lang="ts">
  import {BButton} from 'buefy/src/components/button';
  import dayjs from 'dayjs';
  import dayOfYear from 'dayjs/plugin/dayOfYear';
  import {computed, watch} from 'vue';

  import SslDropdown from './SslDropdown.vue';
  import SslInfoPopover from './SslInfoPopover.ts.vue';

  import {type User} from '@/js/types/models/algemeen';
  import {type Dieetwens, type Maaltijdbon} from '@/js/types/models/cursusreeks';
  import {formatDate, formatDateString, formatTimeString} from '@/js/util/dates';
  import useGetRequest from '@/js/vue/util/getRequest';

  dayjs.extend(dayOfYear);

  interface MaaltijdbonOpties {
    aanpasbaarTot: TISODateTime | null;
    teKoopTot: TISODateTime | null;
    dieetOpties: Record<keyof Dieetwens, Dieetwens>;
  }
  interface MaaltijdbonEndpointResponse {
    leerling: User;
    maaltijdbon: Maaltijdbon | null;
    opties: MaaltijdbonOpties;
  }

  interface Props {
    maaltijdbonEndpoint: string;
    maaltijdbonKopenEndpoint: string;
  }
  const props = defineProps<Props>();

  const {
    result,
  } = useGetRequest<MaaltijdbonEndpointResponse>(
    computed(() => props.maaltijdbonEndpoint),
    {
      leerling: {volledigeNaam: ''},
      maaltijdbon: null,
      opties: {
        aanpasbaarTot: null,
        teKoopTot: null,
        dieetOpties: {} as Record<keyof Dieetwens, Dieetwens>,
      },
    },
  );
  const maaltijdbon = computed(() => result.value.maaltijdbon);
  const leerling = computed(() => result.value.leerling);
  const opties = computed(() => result.value.opties);

  const dieetOpties = computed(() => Object.entries(opties.value.dieetOpties)
    .map((optie) => ({
      text: optie[0],
      value: optie[1],
    })));

  /*
   * Randomiseerfunctie voor maaltijdbon-kleur (als getal) op basis van dag, met de volgende eigenschappen:
   * - totaal 4 verschillende waarden
   * - voor iedere 3 opeenvolgende dagen, buiten een jaarwisseling, zijn de 3 waarden verschillend
   * - de volgorde lijkt zodanig semi-willekeurig dat het lastig te raden is welke waarden voor een dag geldt
   * - de functie is locale-onafhankelijk
   * Zie ook https://www.wolframalpha.com/input?i=%28d+*+13+mod+31%29+mod+4+for+d+in+%281...366%29.
   */
  const randomizedDayNumber = (date: TISODateTime) => ((dayjs(date).dayOfYear() * 13) % 31) % 4;
  const maaltijdbonColor = computed(() => maaltijdbon.value
    ? {
      [`kleur${randomizedDayNumber(maaltijdbon.value.DagDate)}`]: true,
    }
    : {});

  const isToekomstigTijdstip = (dateString: TISODateTime | null) =>
    dateString && (new Date(Date.now()) < new Date(dateString));
  const aanpasbaar = computed(() => maaltijdbon.value && isToekomstigTijdstip(opties.value?.aanpasbaarTot));
  const koopbaar = computed(() => !maaltijdbon.value && isToekomstigTijdstip(opties.value?.teKoopTot));

  const postBody = computed(() => ({
    Dieetwens: maaltijdbon.value?.Dieetwens,
  }));
  const postDieetwens = async() => {
    $http.post(props.maaltijdbonEndpoint, postBody.value);
  };

  watch(
    () => maaltijdbon.value?.Dieetwens,
    (newVal, oldVal) => {
      if (oldVal !== undefined && newVal !== oldVal) {
        postDieetwens();
      }
    },
  );
</script>

<style lang="scss" scoped>
  @use "bulma/sass/utilities/mixins" as bulmaMixins;
  @use "@/sass/variables";

  .wrapper {
    margin-top: 1rem;
  }

  :deep(.maaltijdbon) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    height: 160px;
    width: 280px;
    color: variables.$white !important;

    @include bulmaMixins.mobile {
      // Centreer maaltijdbon op mobiel
      margin-left: auto;
      margin-right: auto;
    }

    .title,
    .subtitle {
      color: variables.$white !important;
    }

    &.kleur0 {
      background: variables.$maaltijdbon-0;
    }

    &.kleur1 {
      background: variables.$maaltijdbon-1;
    }

    &.kleur2 {
      background: variables.$maaltijdbon-2;
    }

    &.kleur3 {
      background: variables.$maaltijdbon-3;
    }

    .control {
      text-align: center;
    }

    .panel {
      // force info-popover to appear over dropdown arrow
      z-index: 10;
    }
  }

  .maaltijdbon-info {
    font-size: 1.4rem;
    vertical-align: center;
  }
</style>
