import {
  merge as mergeRecursive,
  isEqual,
} from 'lodash-es';

export const cloneObject = <T extends object>(obj: T): T => {
  try {
    return structuredClone(obj);
  } catch {
    return JSON.parse(JSON.stringify(obj)); // Fallback behavior for older browsers
  }
};

export const objectsAreEqual = <T extends object>(value: T, other: T) => isEqual(value, other);

export {
  mergeRecursive,
};
