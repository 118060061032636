<template>
  <div>
    <h2 class="is-size-3">
      Extra
    </h2>
    <SslDropdownAlternative
      v-if="!readOnly"
      v-model="data.via"
      :options="options"
      :alternative-option="alternativeOption"
      label="Hoe heb je van SSL gehoord?"
      :validator="$v.data.via"
      @input="updateInput"
    />
  </div>
</template>

<script>
  const viaAlternatiefId = 1; // ID 1 in onze database is Overig

  import {requiredIf} from 'vuelidate/lib/validators';

  import SslDropdownAlternative from '../elements/SslDropdownAlternative.vue';

  export default {
    name: 'PartialFormExtra',
    components: {
      SslDropdownAlternative,
    },
    props: {
      data: {
        type: Object,
        required: true,
      },
      viaOpties: {
        type: Array,
        required: true,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        viaAlternatiefTekst: 'Anders, namelijk...',
      };
    },
    computed: {
      options() {
        return this.viaOpties
          .filter((via) => (via.Via_Leerling_ID !== viaAlternatiefId))
          .map((via) => ({value: via.Via_Leerling_ID, text: via.Omschrijving}));
      },
      alternativeOption() {
        return {
          value: viaAlternatiefId,
          text: this.viaAlternatiefTekst,
        };
      },
    },
    validations: {
      data: {
        via: {
          anders: {
            required: requiredIf((via) => (via.id === viaAlternatiefId)),
          },
        },
      },
    },
    methods: {
      updateInput() {
        this.$emit('input', this.data);
      },
    },
  };
</script>
