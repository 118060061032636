<template>
  <BField :label="label">
    <BSelect
      :value="value"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <option
        v-for="(item, key) in options"
        :key="key"
        :value="item.value"
      >
        {{ item.text }}
      </option>
    </BSelect>
  </BField>
</template>

<script>
  import {BField} from 'buefy/src/components/field';
  import {BSelect} from 'buefy/src/components/select';

  export default {
    name: 'SslDropdown',
    components: {
      BField,
      BSelect,
    },
    props: {
      options: {
        type: Array,
        required: true,
      },
      value: {
        type: [String, Number, Object, Date],
        default: null,
      },
      label: {
        type: String,
        default: '',
      },
    },
    methods: {
      updateInput(key) {
        this.$emit('input', key);
      },
    },
  };
</script>
