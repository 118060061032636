const zoekAdresBaseUri = 'https://api.pdok.nl/bzk/locatieserver/search/v3_1/free';

interface GeodataResult {
  bron: string;
  type: string;
  postcode: string;
  straatnaam: string;
  woonplaatsnaam: string;
}

// Zie https://api.pdok.nl/bzk/locatieserver/search/v3_1/ui/
interface GeodataResponse {
  response: {
    numFound: number;
    start: number;
    docs?: Array<GeodataResult>;
  };
}

interface ZoekAdresResultaat {
  Straat: string | null;
  Plaats: string | null;
}

const zoekAdresQuery = (postcode: string, huisnummer: string) =>
  `?q=postcode:"${postcode}" and huisnummer:"${huisnummer}"&rows=1`;
const zoekAdresEndpoint = (postcode: string, huisnummer: string) =>
  `${zoekAdresBaseUri}${zoekAdresQuery(postcode, huisnummer)}`;

const zoekAdres = async(postcode: string, huisnummer: string): Promise<ZoekAdresResultaat> => $http
  .get<GeodataResponse>(zoekAdresEndpoint(postcode, huisnummer))
  .then((response) => {
    const bestMatch = response.data?.response?.docs?.[0];

    return {
      Straat: bestMatch?.straatnaam ?? null,
      Plaats: bestMatch?.woonplaatsnaam ?? null,
    };
  })
  .catch(() => ({
    Straat: null,
    Plaats: null,
  }));

export default zoekAdres;
